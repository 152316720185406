/* ProfileSidebar.css */
.sidebar {
    width: 250px;
    padding: 20px;
    background-color: #f4f4f4;
  }
  
  /* Piilottaa .sidebar-luokan elementit, kun näytön leveys on 768px tai alle */
  @media (max-width: 768px) {
    .sidebar {
      display: none;
    }
  }
  