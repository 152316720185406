.pac-item {
    font-family: inherit;
        font-size: 16px;
        padding: 10px 14px;
        border: none;
        box-shadow: none;
    }
    .pac-item-query {
        font-size: 16px;
        font-family: inherit;
    }
    .pac-container {
        border: none;
        box-shadow: none;
        border-radius: 0;
        -webkit-border-radius: 8px;
        -moz-border-radius: 8px;
        -ms-border-radius: 8px;
        border-radius: 8px;
        max-height: 368px;
        -webkit-box-shadow: 0 0 10px 0 rgba(0,0,0,.2);
        -moz-box-shadow: 0 0 10px 0 rgba(0,0,0,.2);
        box-shadow: 0 0 10px 0 rgba(0,0,0,.2);
    }