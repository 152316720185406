.custom-alert {
  animation: shake 0.5s ease-in-out 0s 3;
  border-left: 5px solid red;
}

@keyframes shake {
  0% { transform: translateX(0); }
  25% { transform: translateX(-5px); }
  50% { transform: translateX(5px); }
  75% { transform: translateX(-5px); }
  100% { transform: translateX(0); }
}

.main-container {
  display: flex;
  justify-content: center; /* Elementit keskitetään kun tilaa on tarpeeksi */
  align-items: flex-start;
  flex-wrap: wrap; /* Elementit siirtyvät seuraavalle riville, jos tila loppuu */
  gap: 20px;
}

/* Media query pienille näytöille, jossa elementit tasaantuvat vasemmalle */
@media (max-width: 1068px) {
  .main-container {
    justify-content: flex-start; /* Elementit tasaantuvat vasemmalle kun tila ei riitä */
  }
}

/* Yleinen tyylittely */
body {
  font-family: 'Roboto', sans-serif;
  background-color: #f4f7fa;
  color: #333;
}

/* Otsikon tyylittely */
.title {

  font-weight: bold;
  color: #333;

}

/* Lompakko-otsikon tyylittely */
.wallet-title {
  font-size: 20px;
  font-weight: bold;
  color: #fff;
  background-color: #2a3f54;
  padding: 10px;
  border-radius: 8px;
  text-align: center;
  margin-bottom: 20px;
  width: 100%;
}

/* Lompakon saldo -tyylittely */
.wallet-balance {
  background-color: #fff;
  padding: 1px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-align: center;
  color:#0c0014;
  margin-top:20px;
}

/* Lompakon saldo -tyylittely */
.wallet-balance2 {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-align: center;
  color:#0c0014;
  margin-top:20px;
  padding: 16px;
}

/* Korttilistaus -tyylittely */
.cards-display {
  display: flex;
  flex-direction: row; /* Asetetaan lapset pinoon pystysuunnassa */
  align-items: flex-start; /* Asettaa lapset containerin vasempaan reunaan */
}

.card-info {
  align-items: flex-start;
  padding: 15px;
  border-radius: 8px;

}

/* Napit -tyylittely */
button {
  background-color: #4caf50; /* Vihreä */
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s;
}



/* Varoitusviestin tyylittely */
.custom-alert {
  margin-bottom: 20px;
}

/* Containerin tyylittely */
.container_card1 {
  padding: 20px;
  background-color: #e8eef1;
  border-radius: 8px;
  max-width: 450px;
  flex: 1; 
}

.container_card {
  max-width: 380px;
  max-height: 230px;
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #0c0014;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%25' height='100%25' viewBox='0 0 1600 800'%3E%3Cg %3E%3Cpolygon fill='%230d1838' points='1600%2C160 0%2C460 0%2C350 1600%2C50'/%3E%3Cpolygon fill='%230e315d' points='1600%2C260 0%2C560 0%2C450 1600%2C150'/%3E%3Cpolygon fill='%230f4981' points='1600%2C360 0%2C660 0%2C550 1600%2C250'/%3E%3Cpolygon fill='%231062a6' points='1600%2C460 0%2C760 0%2C650 1600%2C350'/%3E%3Cpolygon fill='%23117aca' points='1600%2C800 0%2C800 0%2C750 1600%2C450'/%3E%3C/g%3E%3C/svg%3E");
  background-size: auto 147%;
  background-position: center;
  position: relative;
  box-sizing: border-box;
  
}
.input-group {
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 10px; /* Väli inputin ja painikkeen välillä */
}
.currency-symbol {
  position: absolute;
  right: 10px; /* Säädä tämä arvo niin, että € on oikeassa kohdassa */
  top:6px;
  color: #2a3f54;
  font-size: 19px;
}

 /* Jos käytät Stripe Elements -komponentteja, voit määrittää niiden tyylin näin */
 .StripeElement2 {
  flex-grow: 1; /* Tämän avulla input täyttää koko tilan paitsi € merkin tilan */
  border: 2px solid #ffffff;
  padding: 10px;
  font-size: 16px;
  color: #000;
  background-color: rgba(255, 255, 255, 0.8); /* Kevyt läpinäkyvyys */
  border-radius: 4px;
}
/* Painike tarvitsee oman tyylinsä */
.add-funds-button {
  background-color: #4caf50; /* Vihreä */
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s;
}
.add-funds-button:hover {
  background-color: #45a049;
}
 /* Jos käytät Stripe Elements -komponentteja, voit määrittää niiden tyylin näin */
 .StripeElement {
  border: 2px solid #ffffff;
  margin-bottom: 10px;
  border-radius: 4px;
  padding: 10px;
  font-size: 16px;
  color: #ffffff;
  background-color: rgba(255, 255, 255, 0.8); /* Kevyt läpinäkyvyys */
}

/* Yksittäisen kortin tyylittely */
.addcard {
  background-color: #2a3f54;
  color: #fff;
  padding: 15px;
  border-radius: 8px;
  min-width:400px;
  min-height: 273px;
}

.visa_logo {
  float: right;
  padding: 10px;
}

.visa_logo {

  height: 40px;
  text-transform: uppercase;
  color:#ffffff;
  padding: 10px 10px;
  font-size: 24px;
}

.visa_info {
  padding: 10px;
  margin: 30px 0;
}
.visa_info img {
  width: 60px;
  height: 45px;
}

.visa_info p {
  font-size: 22px;
  padding: 10px 0;
  letter-spacing: 2px;
  color: #ffffff;
}

.visa_crinfo {
  display: flex;
  justify-content: space-between;
  padding: 0 56px;
  color: #ffffff;
  margin: -50px;
}

.card2 {
  width: 380px;
  height: 230px;
  border: 2px solid rgba(255, 255, 255, 0.2);
  border-radius: 10px;
  z-index: 1;
  overflow: hidden;
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  position: relative;

}