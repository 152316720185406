.dashboard-container {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    padding: 20px;
    box-sizing: border-box;
}

.grid-item1 {

    border-radius: 8px;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    padding: 20px;
}

.grid-item {

    border-radius: 8px;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    padding: 20px;
}

/* Mobiilinäkymä */
@media (max-width: 768px) {
    .dashboard-container {
        flex-direction: column;
    }
    .grid-item {
        width: 100% !important;
    }
}
