/* components/key-account.css */

.container {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f5f5f5;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.loginContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh; /* Vie koko näytön korkeuden */
    background-color: #f5f5f5; /* Taustaväri */
  }
  
  .loginContainer input {
    padding: 10px;
    margin-bottom: 15px; /* Väli salasana-kentän ja painikkeen välillä */
    border-radius: 5px;
    border: 1px solid #ccc;
    width: 300px; /* Salasana-kentän leveys */
  }
  
  .loginContainer button {
    padding: 10px 20px;
    border-radius: 5px;
    border: none;
    background-color: #4CAF50; /* Painikkeen taustaväri */
    color: white;
    cursor: pointer;
    font-size: 16px;
  }
  
  .loginContainer button:hover {
    background-color: #45a049; /* Painikkeen taustaväri kun hiiri on päällä */
  }
  

.form {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.label {
    font-weight: bold;
}

.custom-alert {
    position: fixed;
    top: 20px;
    left: 50%;
    transform: translateX(-50%);
    background-color: #4CAF50;
    color: white;
    padding: 10px 20px;
    border-radius: 5px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
    z-index: 1000;
}
.input_adress {
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    box-shadow: 0px 2px 5px rgba(0,0,0,0.1); /* kevyt varjostus */
    width:97%;
}

.image {
    top: 0;
    left: 0;
    width: 150px !important;
  }

.emailMessage {
    margin-top: 20px; /* Lisää tarvittaessa marginaalia */
    padding: 10px; /* Lisää paddingia sisältöön */
    border: 1px solid #ccc; /* Lisää kehys */
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1); /* Lisää varjostusta */
    resize: none; /* Poista käyttäjän koon muuttamismahdollisuus */
}


.form button {
    background-color: #4CAF50;
    color: white;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.form button:hover {
    background-color: #45a049;
}

.link-container {
    margin-top: 20px;
    background-color: #e7e7e7;
    padding: 10px;
    border-radius: 5px;
}

.inputField {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* kaksi saraketta */
    gap: 10px; /* väli sarakkeiden välillä */
    margin-bottom: 15px; /* väli kenttien alapuolella */
}

.input, .form button {
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    box-shadow: 0px 2px 5px rgba(0,0,0,0.1); /* kevyt varjostus */
}

.input:focus {
    outline: none;
    border-color: #4CAF50;
    box-shadow: 0px 0px 8px rgba(0, 255, 0, 0.2); /* korostus kun kenttä on aktiivinen */
}